var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status
    ? _c(
        "b-modal",
        {
          attrs: {
            id: "modalOrderV3",
            size: "md",
            title: _vm.getModalTitle,
            "ok-title": _vm.getOkButton,
            "cancel-title": "Cancelar",
            "ok-disabled": _vm.isLoading,
          },
          on: {
            ok: _vm.handleOk,
            show: _vm.handleModalOpen,
            hide: _vm.handleModalClose,
          },
        },
        [
          !_vm.$apollo.queries.lockReasons.loading
            ? _c(
                "div",
                [
                  _vm.isUnlocked
                    ? [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.validateVin("lock")
                              },
                            },
                          },
                          [
                            _c("h4", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(`fleetProfile.labels.blockReasonTitle`)
                                )
                              ),
                            ]),
                            _c(
                              "select",
                              { on: { change: _vm.handleLockSelect } },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", selected: "" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(`fleetProfile.texts.select`)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "optgroup",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        `fleetProfile.labels.emergencyGroup`
                                      ),
                                    },
                                  },
                                  _vm._l(
                                    _vm.emergencyLockReasons[0],
                                    function (reason, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: { value: reason },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  `fleetProfile.lockReasons.${reason}`
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "optgroup",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        `fleetProfile.labels.nonEmergencyGroup`
                                      ),
                                    },
                                  },
                                  _vm._l(
                                    _vm.emergencyLockReasons[1],
                                    function (reason, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: { value: reason },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  `fleetProfile.lockReasons.${reason}`
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.isLocked
                    ? [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.validateVin("unlock")
                              },
                            },
                          },
                          [
                            _c("h4", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `fleetProfile.labels.unblockReasonTitle`
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "select",
                              { on: { change: _vm.handleUnlockSelect } },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", selected: "" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(`fleetProfile.texts.select`)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.unlockReasons,
                                  function (reason, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: reason },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                `fleetProfile.unlockReasons.${reason}`
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.isPending
                    ? [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.validateVin("unlock")
                              },
                            },
                          },
                          [
                            _c("h4", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(`fleetProfile.modals.cancelLock.text`)
                                )
                              ),
                            ]),
                            _c(
                              "select",
                              { on: { change: _vm.handleUnlockSelect } },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", selected: "" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(`fleetProfile.texts.select`)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.unlockReasons,
                                  function (reason, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: reason },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                `fleetProfile.unlockReasons.${reason}`
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _c("div", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t(`fleetProfile.labels.loading`)) + "..."),
                ]),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }