var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "region-guard",
        { attrs: { id: _vm.$route.params.id, entity: "DRIVER" } },
        [
          _c("driver-header", { attrs: { id: _vm.$route.params.id } }),
          _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }