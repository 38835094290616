<template>
  <div class="row">
    <b-col lg="auto" md="auto" class="mr-0 pr-0">
      <driver-karma
        v-if="profile.status !== 'SIGNUP'"
        :profile="profile"
      />
    </b-col>
    <div class="d-inline">
      <k-avatar
        :src="profile.photo"
        :title="$t('drivers.labels.showImage')"
        size="md"
      />
    </div>
    <b-col lg="8" md="10" class="ml-0 pl-0">
      <h4 class="card-title m-0">
        <b-link
          :to="`/drivers/profile/${id}`"
          class="card-name mr-1"
          :title="profile.name"
        >
          {{ profile.name || $t('drivers.texts.anonymous') }}
        </b-link>
      </h4>
      <div
        v-if="isActiveDriver"
        class="text-muted font-weight-normal mb-1"
      >
        {{ $t('drivers.labels.status') }}:
        <b-badge
          :variant="setStatusBadge(profile.status)"
        >
          {{ statusBadge }}
        </b-badge>
        - {{ $t('drivers.labels.register') }}
        <span
          v-if="profile.created_at"
        >
          {{ profile.created_at | moment('from', true) }}
        </span>
        <span v-else>
          0 {{ $t('drivers.labels.days') }}
        </span>
        {{ $t('drivers.labels.behind') }}
      </div>
      <div v-else class="text-muted font-weight-normal mb-1">
        {{ $t('drivers.labels.situationRegister') }}:
        <b-badge v-if="profile.status === 'BANNED'" variant="danger" class="text-uppercase">
          {{ $t('drivers.labels.banned') | up }}
        </b-badge>
        <b-badge v-else variant="warning">
          {{ profile.step }}
        </b-badge>
        -
        {{ $t('drivers.labels.register') }}
        <span
          v-if="profile.created_at"
        >{{ profile.created_at | moment('from', true) }}</span>
        <span v-else>0 {{ $t('drivers.labels.days') }}</span>
        {{ $t('drivers.labels.behind') }}
      </div>
      <div>
        <b-button
          v-if="profile.booking && profile.booking.car"
          class="pt-0 pl-0 text-uppercase"
          link
          size="sm"
          variant="link"
          :to="`/fleet/${profile.booking.car.id}`"
        >
          <i class="fa fa-car" />
          {{ infoCar }}
        </b-button>
        <br>
        <span
          v-if="!!planName"
          class="text-muted mb-1"
        >
          Plano: <b-badge class="bg-kovi">{{ planName }}</b-badge>
        </span>
        <div id="clip-cpf" class="text-muted mt-1">
          {{ $t('drivers.labels.document') }}: {{ profile.cpf }}
          <b-button variant="link" size="sm" @click="copyCpf">
            <i class="fa fa-clone" aria-hidden="true" />
          </b-button>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import KAvatar from '@components/shared/k-avatar.vue'
import DriverKarma from '@components/drivers/driver-karma';
import { formatStatus } from '@utils/driver';
import ClipboardJS from 'clipboard';
export default {
	name: 'ProfileInfo',
  components: {
    KAvatar,
    DriverKarma
  },
	props: {
		id: {
			type: String,
			required: true
		},
		profile: {
			type: Object,
			required: true
		}
	},
	computed: {
    isActiveDriver() {
      return (
        this.profile.status == 'ACTIVE' ||
        this.profile.status == 'INACTIVE'
      )
    },
		infoCar () {
			const { car } = this.profile.booking
			return `${car.license_plate} - ${car.brand.name} ${car.model.name}`
		},
		statusBadge () {
      return this.$t('drivers.statusEnum.' + this.profile.status);
			// switch (this.profile.status) {
    },
    planName () {
      return this.profile.booking?.plan?.name || ''
    }
	},
	methods: {
    setStatusBadge(status) {
      return formatStatus(status);
    },
    copyCpf () {
      const clipboard = new ClipboardJS('#clip-cpf',
        {
          text: () => this.profile.cpf
        }
      );

      clipboard.on('success', (e) => {
        this.$snotify.success(this.$t('payments.labels.clipboardSuccess'));
        e.clearSelection();
        clipboard.destroy();
      });

      clipboard.on('error', () => {
        this.$snotify.error(this.$t('payments.labels.clipboardError'));
        clipboard.destroy();
      });
    }
	}
}
</script>

<style scoped>
.bg-kovi {
  background: #FF617b;
  color: #FFF;
}

</style>
