var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav",
    { attrs: { tabs: "" } },
    [
      _c(
        "b-nav-item",
        {
          attrs: { exact: "", to: "/drivers/profile/" + _vm.$route.params.id },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("drivers.nav_profile.driver_profile")) + " "
          ),
        ]
      ),
      _c(
        "b-nav-item",
        {
          attrs: {
            exact: "",
            to: "/drivers/profile/" + _vm.$route.params.id + "/wallet",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("drivers.nav_profile.wallet")) + " ")]
      ),
      _vm.$regions.isBrazil()
        ? _c(
            "b-nav-item",
            {
              attrs: {
                "data-test": "nav-negotiation",
                exact: "",
                to:
                  "/drivers/profile/" + _vm.$route.params.id + "/negotiations",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("drivers.nav_profile.negotiations")) + " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "b-nav-item",
        {
          attrs: {
            exact: "",
            to: "/drivers/profile/" + _vm.$route.params.id + "/bookings",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("drivers.nav_profile.bookings")) + " ")]
      ),
      _c(
        "b-nav-item",
        {
          attrs: {
            exact: "",
            to: "/drivers/profile/" + _vm.$route.params.id + "/fines",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("drivers.nav_profile.fines")) + " ")]
      ),
      _vm.$regions.isBrazil()
        ? _c(
            "b-nav-item",
            {
              attrs: {
                exact: "",
                to: "/drivers/profile/" + _vm.$route.params.id + "/repayment",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("drivers.nav_profile.repayment")) + " "
              ),
            ]
          )
        : _vm._e(),
      _vm.driver.status != "BANNED"
        ? _c(
            "b-nav-item-dropdown",
            {
              attrs: {
                id: "my-nav-dropdown",
                text: _vm.$t("drivers.nav_profile.scheduling"),
                right: "",
              },
            },
            [
              _c(
                "b-dropdown-item",
                { on: { click: _vm.openModalDeliveryCarSchedule } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$i18n.t("drivers.titles.delivery")) + " "
                  ),
                ]
              ),
              _c(
                "b-dropdown-item",
                { on: { click: _vm.openModalDevolutionCarSchedule } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$i18n.t("drivers.titles.devolution")) + " "
                  ),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: { click: _vm.openModalDeliveryAndDevolutionCarSchedule },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$i18n.t("drivers.titles.deliveryAndDevolution")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-dropdown-item",
                { on: { click: _vm.openModalInspection } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$i18n.t("drivers.titles.inspection")) + " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-nav-item",
        {
          attrs: {
            exact: "",
            to: "/drivers/profile/" + _vm.$route.params.id + "/block-history",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("drivers.nav_profile.blockHistory")) + " ")]
      ),
      _c(
        "b-nav-item",
        {
          attrs: {
            exact: "",
            to: "/drivers/profile/" + _vm.$route.params.id + "/chat-events",
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("drivers.nav_profile.chatCommunication")) + " "
          ),
        ]
      ),
      _c(
        "b-nav-item",
        {
          attrs: {
            exact: "",
            to: "/drivers/profile/" + _vm.$route.params.id + "/checklist",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("drivers.nav_profile.checklist")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }