<template>
  <b-nav tabs>
    <b-nav-item exact :to="'/drivers/profile/' + $route.params.id">
      {{ $t('drivers.nav_profile.driver_profile') }}
    </b-nav-item>
    <b-nav-item exact :to="'/drivers/profile/' + $route.params.id + '/wallet'">
      {{ $t('drivers.nav_profile.wallet') }}
    </b-nav-item>

    <b-nav-item v-if="$regions.isBrazil()" data-test="nav-negotiation" exact :to="'/drivers/profile/' + $route.params.id + '/negotiations'">
      {{ $t('drivers.nav_profile.negotiations') }}
    </b-nav-item>

    <b-nav-item exact :to="'/drivers/profile/' + $route.params.id + '/bookings'">
      {{ $t('drivers.nav_profile.bookings') }}
    </b-nav-item>
    <b-nav-item exact :to="'/drivers/profile/' + $route.params.id + '/fines'">
      {{ $t('drivers.nav_profile.fines') }}
    </b-nav-item>
    <b-nav-item v-if="$regions.isBrazil()" exact :to="'/drivers/profile/' + $route.params.id + '/repayment'">
      {{ $t('drivers.nav_profile.repayment') }}
    </b-nav-item>

    <b-nav-item-dropdown id="my-nav-dropdown" :text="$t('drivers.nav_profile.scheduling')" right v-if="driver.status != 'BANNED'">
      <b-dropdown-item @click="openModalDeliveryCarSchedule">
        {{ $i18n.t('drivers.titles.delivery') }}
      </b-dropdown-item>
      <b-dropdown-item @click="openModalDevolutionCarSchedule">
        {{ $i18n.t('drivers.titles.devolution') }}
      </b-dropdown-item>

      <b-dropdown-item @click="openModalDeliveryAndDevolutionCarSchedule">
        {{ $i18n.t('drivers.titles.deliveryAndDevolution') }}
      </b-dropdown-item>

      <b-dropdown-item @click="openModalInspection">
        {{ $i18n.t('drivers.titles.inspection') }}
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item exact :to="'/drivers/profile/' + $route.params.id + '/block-history'">
      {{ $t('drivers.nav_profile.blockHistory') }}
    </b-nav-item>

    <b-nav-item exact :to="'/drivers/profile/' + $route.params.id + '/chat-events'">
      {{ $t('drivers.nav_profile.chatCommunication') }}
    </b-nav-item>

    <b-nav-item exact :to="'/drivers/profile/' + $route.params.id + '/checklist'">
      {{ $t('drivers.nav_profile.checklist') }}
    </b-nav-item>
  </b-nav>
</template>

<script>
import { mapGetters } from 'vuex';
import GET_DRIVER from '@graphql/driver/queries/get.gql';
export default {
  name: 'ProfileNav',
  computed: {
    ...mapGetters('user', ['findGroups']),
  },
  methods: {
    openModalDeliveryCarSchedule() {
      this.$root.$emit('bv::show::modal', 'deliveryCarSchedule');
    },
    openModalDevolutionCarSchedule() {
      this.$root.$emit('bv::show::modal', 'devolutionCarSchedule');
    },
    openModalDeliveryAndDevolutionCarSchedule() {
      this.$root.$emit('bv::show::modal', 'deliveryAndDevolutionCarSchedule');
    },
    openModalInspection() {
      this.$root.$emit('bv::show::modal', 'inspectionCarSchedule');
    },
  },
  apollo: {
    driver: {
      query: GET_DRIVER,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  }
};
</script>
