<template>
	<b-img
		:src="src"
		rounded
		blank-color="#C0C0C0"
		:title="title"
		class="mr-2 mt-1"
    	:class="size"
	/>
</template>

<script>
export default {
	name: 'k-avatar',
	props: {
		src: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: false
    },
    size: {
      type: String,
      required: false
    }
	}
}
</script>

<style scoped>
.sm {
  width: 44px;
  cursor: zoom-in;
}
.md {
  width: 64px;
  cursor: zoom-in;
}
.lg{
  width: 74px;
  cursor: zoom-in;
}
</style>