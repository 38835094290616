<template>
  <div v-if="id" class="fleet-group-buttons">
    <div>
      <b-button v-if="!showNewButton" class="mr-2" :disabled="canResetToken"
                @click="confirmResetToken()"
      >
        <span>Resetar Token</span>
      </b-button>
      <b-button v-if="!showNewButton && !isRental && findGroups('ACL:SAFETY:VIRTUAL_TOKEN')" class="mr-2" @click="confirmVirtualToken()">
        <span>Liberar Token</span>
      </b-button>
      <b-button-group v-if="!hidde" :class="newButtonClass">
        <b-button :disabled="isDisabled" :class="noSignalClass" @click="changeOperationCarV2()">
          <template v-if="status === 'UNLOCK_PENDING'">
            <i class="fa fa-clock-o mr-2" />
            {{ noSignalWarning ? $t('fleetProfile.buttons.noSignalUnlocking') : $t('fleetProfile.buttons.unlocking') }}
          </template>

          <template v-if="['LOCKED', 'LOCK_PENDING', 'UNLOCKED'].includes(status)">
            <span v-if="status === 'UNLOCKED'">
              <i class="fa fa-unlock mr-2" />
              {{ $t('fleetProfile.buttons.lock') }}
            </span>
            <span v-if="status === 'LOCKED'">
              <i class="fa fa-lock mr-2" />
              {{ $t('fleetProfile.buttons.unlock') }}
            </span>
            <span v-if="status === 'LOCK_PENDING'">
              <i class="fa fa-clock-o mr-2" />
              {{ noSignalWarning ? $t('fleetProfile.buttons.noSignalLocking') : $t('fleetProfile.buttons.locking') }} - {{ $t('fleetProfile.buttons.cancel') }}
            </span>
          </template>

          <template v-if="!status">
            <i class="fa fa-clock-o mr-2" />
            <span>Carregando...</span>
          </template>
        </b-button>
      </b-button-group>
    </div>
    <modal-operation-v2 :id="id" :vin="vin" :is-rental="isRental" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalOperationV2 from './modal-operation-v2';
import RESET_TOKEN from '@graphql/fleet/mutations/reset-token.gql';
import VIRTUAL_TOKEN from '@graphql/fleet/mutations/virtual-token.gql';
import CAR_TRACKING from '@graphql/car/queries/tracking.gql';
import moment from 'moment';
import { emails } from './fleet-group-buttons-emails';

export default {
  components: {
    ModalOperationV2,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    vin: {
      type: String,
      required: true,
    },
    isRental: {
      type: Boolean,
      required: false,
      default: true,
    },
    showNewButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      profile: 'fleet/header',
    }),
    ...mapGetters('user', ['findGroups']),
    status: function () {
      return this.isNewButton ? this.tracking?.deviceStatus : this.profile.status_operation;
    },
    isNewButton: function () {
      const userEmail = this.$store.getters['user/attributes'].email;
      return emails.includes(userEmail) && this.showNewButton;
    },
    hidde: function () {
      return process.env.VUE_APP_NEW_LOCK_UNLOCK_BUTTON === 'true';
    },
    newButtonClass: function () {
      return this.isNewButton ? 'btn-group-new' : '';
    },
    noSignalClass: function () {
      return this.profile.status_operation && this.noSignalWarning ? 'btn-group-no-signal' : '';
    },
    noSignalWarning: function () {
      if (!this.tracking) return false;

      if (['LOCKED', 'UNLOCKED'].includes(this.profile.status_operation)) return false;

      const lastEventAt = moment(this.tracking.lastEventAt);
      const diffInMinutes = moment().diff(lastEventAt, 'minute');

      return diffInMinutes > 20;
    },
    canResetToken() {
      const groups = this.$store.getters['user/groups'];

      // Check if it a kovi's device
      const isKoviDevice = this.profile?.tracking?.provider === 'KOVI';

      // Check acl to allow access
      const isAllowed =
        groups.includes('ACL:SAFETY:RESET_TOKEN') || // Insurance Team
        groups.includes('ACL:GOD'); // Product Team

      return !(isKoviDevice && isAllowed);
    },
    isDisabled: function () {
      const groups = this.$store.getters['user/groups'];

      const actionIsAvailable = ['UNLOCKED', 'LOCKED', 'LOCK_PENDING'].includes(this.profile.status_operation);

      // Check acl to allow access
      const isAllowed =
        groups.includes('Security') || // Safety Team
        groups.includes('ACL:GOD') || // Product Team
        groups.includes('ACL:SAFETY:LOCK') ||
        groups.includes('ACL:SAFETY:LOCK_V2') ||
        groups.includes('ACL:SAFETY:CANCEL_LOCK');

      const isAllowedInsurance = groups.includes('Security') || groups.includes('ACL:GOD');

      // Check if product is Rental
      const isProductRental = this.profile.product === 'RENTAL';

      if (isAllowed && actionIsAvailable && isProductRental) return false;
      return !(isAllowedInsurance && actionIsAvailable && !isProductRental);
    },
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
    ...mapActions({
      clearHeader: 'fleet/clearHeader',
      setHeader: 'fleet/header',
      setStatusOp: 'fleet/setStatusOperation',
    }),
    loadProfile() {
      this.clearHeader({ id: this.id });
      this.setHeader({ id: this.id });
    },
    confirmResetToken() {
      // avisar quando finalizar entrega
      const configSwal = {
        type: 'warning',
        title: this.$t('fleetProfile.modals.resetToken.title'),
        text: this.$t('fleetProfile.modals.resetToken.text'),
        showConfirmButton: true,
        confirmButtonText: this.$t('fleetProfile.modals.resetToken.buttons.confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('fleetProfile.modals.resetToken.buttons.cancel'),
        showLoaderOnConfirm: true,
        preConfirm: () => this.sendResetToken(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;

          const configSwalSuccess = {
            type: 'success',
            title: this.$t('fleetProfile.modals.resetToken.results.success.text'),
            text: '',
          };

          this.$swal(configSwalSuccess);
        })
        .catch(() => {
          const configSwalFail = {
            type: 'error',
            title: this.$t('fleetProfile.modals.resetToken.results.error.text'),
            text: '',
          };

          this.$swal(configSwalFail);
        });
    },
    confirmVirtualToken() {
      const configSwal = {
        type: 'warning',
        title: 'Liberar token',
        text: 'Bater o Token remotamente',
        showConfirmButton: true,
        confirmButtonText: this.$t('fleetProfile.modals.virtualToken.buttons.confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('fleetProfile.modals.virtualToken.buttons.cancel'),
        showLoaderOnConfirm: true,
        preConfirm: () => this.sendVirtualToken(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;

          const configSwalSuccess = {
            type: 'success',
            title: this.$t('fleetProfile.modals.virtualToken.results.success.text'),
            text: '',
          };

          this.$swal(configSwalSuccess);
        })
        .catch(() => {
          const configSwalFail = {
            type: 'error',
            title: this.$t('fleetProfile.modals.virtualToken.results.error.text'),
            text: '',
          };

          this.$swal(configSwalFail);
        });
    },

    sendResetToken() {
      return this.$apollo
        .mutate({
          mutation: RESET_TOKEN,
          variables: {
            input: {
              car: this.id,
            },
          },
        })
        .then(data => data)
        .catch(err => {
          throw new Error(err);
        });
    },
    sendVirtualToken() {
      return this.$apollo
        .mutate({
          mutation: VIRTUAL_TOKEN,
          variables: {
            input: {
              car: this.id,
            },
          },
        })
        .then(data => data)
        .catch(err => {
          throw new Error(err);
        });
    },
    async changeOperationCarV2() {
      this.$root.$emit('bv::show::modal', 'modalOrderV2');
    },
  },
  apollo: {
    tracking: {
      query: CAR_TRACKING,
      variables() {
        return {
          input: {
            id: this.id,
          },
        };
      },
      update: data => {
        return data.getTracking;
      },
      fetchPolicy: 'network-only',
    },
  },
};
</script>

<style lang="scss" scoped>
.fleet-group-buttons {
  display: inline-block;
  margin-right: 0.5rem;
}

.btn-group-new {
  position: relative;
  &::after {
    content: 'Teste';
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    font-size: 10px;
    width: 35px;
    height: 20px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
  }
}

.btn-group-no-signal {
  background-color: red;
  color: white;
}
</style>
