var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.profile
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "pb-1 justify-content-start" },
                [
                  _c("b-col", { attrs: { md: "6", sm: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c("profile-info", {
                          attrs: { id: _vm.id, profile: _vm.profile },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-md-right text-sm-left btn-content",
                      attrs: { md: "6", sm: "12" },
                    },
                    [
                      _c(
                        "b-dropdown",
                        { staticClass: "mr-2", attrs: { text: "Mensagem" } },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-test": "driver-header__select-sms",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.newMessage()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-comments" }),
                              _vm._v(" SMS + Email "),
                            ]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                href:
                                  "https://web.whatsapp.com/send?phone=" +
                                  _vm.profile.phone_number,
                                target: "_blank",
                                "data-test": "driver-header__select-whatsapp",
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-whatsapp" }),
                              _vm._v(" WhatsApp "),
                            ]
                          ),
                        ],
                        1
                      ),
                      !_vm.driverEditProfilePage
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.goTo()
                                },
                              },
                            },
                            [_c("span", [_vm._v("Editar")])]
                          )
                        : _vm._e(),
                      _vm.hasBookCar
                        ? _c("fleet-group-buttons", {
                            attrs: { vin: _vm.vin, id: _vm.bookingCarId },
                          })
                        : _vm._e(),
                      _c("fleet-lock-unlock-button", {
                        attrs: { id: _vm.bookingCarId, vin: _vm.vin },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pt-3 pb-2", attrs: { md: "12", sm: "12" } },
                    [_c("profile-nav")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("contact-driver", { attrs: { modal_data: _vm.contact_modal_data } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }