<template>
  <div v-if="!hidde && id" class="btn-content">
    <b-button :disabled="isDisabled" :class="noSignalClass" @click="changeOperationCarV2()">
      <i :class="iconClass" />
      {{ statusText }}
    </b-button>
    <modal-operation-v3 :id="id" :vin="vin" :status="status" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CAR_TRACKING from '@graphql/car/queries/tracking.gql';
import moment from 'moment';
import ModalOperationV3 from '@components/fleet/modal-operation-v3.vue';
import {emails} from "@components/fleet/fleet-group-buttons-emails";

export default {
  components: { ModalOperationV3 },
  props: {
    id: {
      type: String,
      required: true,
    },
    vin: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      profile: 'fleet/header',
    }),
    ...mapGetters('user', ['findGroups']),
    status: function () {
      return this.tracking?.deviceStatus;
    },
    statusText: function () {
      if (!this.status) {
        return this.$t('fleetProfile.labels.loading');
      }

      if (this.status === 'LOCK_PENDING') {
        return this.noSignalWarning
          ? this.$t('fleetProfile.buttons.noSignalLocking')
          : [this.$t('fleetProfile.buttons.locking'), this.$t('fleetProfile.buttons.cancel')].join(' - ');
      }
      if (this.status === 'UNLOCK_PENDING') {
        return this.noSignalWarning ? this.$t('fleetProfile.buttons.noSignalUnlocking') : this.$t('fleetProfile.buttons.unlocking');
      }

      if (this.status === 'UNLOCKED') {
        return this.$t('fleetProfile.buttons.lock');
      }
      if (this.status === 'LOCKED') {
        return this.$t('fleetProfile.buttons.unlock');
      }
      return '';
    },
    iconClass: function () {
      const classes = ['fa', 'mr-2'];

      switch (this.status) {
        case 'UNLOCKED':
          classes.push('fa-unlock');
          break;
        case 'LOCKED':
          classes.push('fa-lock');
          break;
        default:
          classes.push('fa-clock-o');
      }
      return classes.join(' ');
    },
    hidde: function () {
      if(!process.env.VUE_APP_NEW_LOCK_UNLOCK_BUTTON) {
        const userEmail = this.$store.getters['user/attributes'].email;
        return !emails.includes(userEmail) || !this.id;
      }
      return process.env.VUE_APP_NEW_LOCK_UNLOCK_BUTTON !== 'true';
    },
    noSignalClass: function () {
      return this.status && this.noSignalWarning ? 'btn-group-no-signal' : '';
    },
    noSignalWarning: function () {
      if (!this.tracking) return false;

      if (['LOCKED', 'UNLOCKED'].includes(this.status)) return false;

      const lastEventAt = moment(this.tracking.lastEventAt);
      const diffInMinutes = moment().diff(lastEventAt, 'minute');

      return diffInMinutes > 20;
    },
    isDisabled: function () {
      const groups = this.$store.getters['user/groups'];

      const actionIsAvailable = ['UNLOCKED', 'LOCKED', 'LOCK_PENDING'].includes(this.status);

      // Check acl to allow access
      const isAllowed =
        groups.includes('Security') || // Safety Team
        groups.includes('ACL:GOD') || // Product Team
        groups.includes('ACL:SAFETY:LOCK') ||
        groups.includes('ACL:SAFETY:LOCK_V2') ||
        groups.includes('ACL:SAFETY:CANCEL_LOCK');

      const isAllowedInsurance = groups.includes('Security') || groups.includes('ACL:GOD');

      // Check if product is Rental
      const isProductRental = this.profile.product === 'RENTAL';

      if (isAllowed && actionIsAvailable && isProductRental) return false;
      return !(isAllowedInsurance && actionIsAvailable && !isProductRental);
    },
  },
  methods: {
    async changeOperationCarV2() {
      this.$root.$emit('bv::show::modal', 'modalOrderV3');
    },
  },
  apollo: {
    tracking: {
      query: CAR_TRACKING,
      variables() {
        return {
          input: {
            id: this.id,
          },
        };
      },
      update: data => {
        return data.getTracking;
      },
      fetchPolicy: 'network-only',
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-content {
  position: relative;
  &::after {
    content: 'Teste';
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    font-size: 10px;
    width: 35px;
    height: 20px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
  }
}

.btn-group-no-signal {
  background-color: red;
  color: white;
}
</style>
