<template>
  <b-modal
    v-if="status"
    id="modalOrderV3"
    size="md"
    :title="getModalTitle"
    :ok-title="getOkButton"
    cancel-title="Cancelar"
    :ok-disabled="isLoading"
    @ok="handleOk"
    @show="handleModalOpen"
    @hide="handleModalClose"
  >
    <div v-if="!$apollo.queries.lockReasons.loading">
      <template v-if="isUnlocked">
        <form @submit.stop.prevent="validateVin('lock')">
          <h4>{{ $t(`fleetProfile.labels.blockReasonTitle`) }}</h4>
          <select @change="handleLockSelect">
            <option disabled selected>
              {{ $t(`fleetProfile.texts.select`) }}
            </option>
            <optgroup :label="$t(`fleetProfile.labels.emergencyGroup`)">
              <option v-for="(reason, index) in emergencyLockReasons[0]" :key="index" :value="reason">
                {{ $t(`fleetProfile.lockReasons.${reason}`) }}
              </option>
            </optgroup>
            <optgroup :label="$t(`fleetProfile.labels.nonEmergencyGroup`)">
              <option v-for="(reason, index) in emergencyLockReasons[1]" :key="index" :value="reason">
                {{ $t(`fleetProfile.lockReasons.${reason}`) }}
              </option>
            </optgroup>
          </select>
        </form>
      </template>
      <template v-if="isLocked">
        <form @submit.stop.prevent="validateVin('unlock')">
          <h4>{{ $t(`fleetProfile.labels.unblockReasonTitle`) }}</h4>
          <select @change="handleUnlockSelect">
            <option disabled selected>
              {{ $t(`fleetProfile.texts.select`) }}
            </option>
            <option v-for="(reason, index) in unlockReasons" :key="index" :value="reason">
              {{ $t(`fleetProfile.unlockReasons.${reason}`) }}
            </option>
          </select>
        </form>
      </template>
      <template v-if="isPending">
        <form @submit.stop.prevent="validateVin('unlock')">
          <h4>{{ $t(`fleetProfile.modals.cancelLock.text`) }}</h4>
          <select @change="handleUnlockSelect">
            <option disabled selected>
              {{ $t(`fleetProfile.texts.select`) }}
            </option>
            <option v-for="(reason, index) in unlockReasons" :key="index" :value="reason">
              {{ $t(`fleetProfile.unlockReasons.${reason}`) }}
            </option>
          </select>
        </form>
      </template>
    </div>
    <div v-else>
      <h4>{{ $t(`fleetProfile.labels.loading`) }}...</h4>
    </div>
  </b-modal>
</template>

<script>
import { enumStatusOperation } from '@utils/car';
import { mapActions } from 'vuex';
import LOCK_CAR_V2 from '@graphql/fleet/mutations/lock-car-v2.gql';
import UNLOCK_CAR_V2 from '@graphql/fleet/mutations/unlock-car-v2.gql';
import LOCK_REASONS from '@graphql/fleet/queries/lock-reasons.gql';
import UNLOCK_REASONS from '@graphql/fleet/queries/unlock-reasons.gql';
import GET_LAST_COMMAND from '@graphql/fleet/queries/get-last-command.gql';
import CAR_TRACKING from '@graphql/car/queries/tracking.gql';

export default {
  name: 'ModalOperationV3',
  props: {
    vin: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      getLastCommand: null,
      lockReasonSelected: null,
      unlockReasonSelected: null,
      lockReasons: [],
      unlockReasons: [],
      lockReasonError: false,
      unlockReasonError: false,
      enum_status_operation: enumStatusOperation,
      action_operation: null,
      new_status_operation: null,
      isLoading: false,
      windowClosed: true,
      errorMessage: {
        show: false,
        message: '',
      },
    };
  },
  apollo: {
    lockReasons: {
      query: LOCK_REASONS,
      skip() {
        return this.windowClosed;
      },
    },
    unlockReasons: {
      query: UNLOCK_REASONS,
      skip() {
        return this.windowClosed;
      },
    },
    tracking: {
      query: CAR_TRACKING,
      variables() {
        return {
          input: {
            id: this.id,
          },
        };
      },
      skip() {
        return !this.windowClosed;
      },
      update: data => {
        return data.getTracking;
      },
      fetchPolicy: 'network-only',
    },
    getLastCommand: {
      query: GET_LAST_COMMAND,
      variables() {
        return {
          input: {
            vin: this.vin,
          },
        };
      },
      skip() {
        return !this.vin;
      },
    },
  },
  computed: {
    emergencyLockReasons() {
      const emergencyLock = ['FRAUD', 'THEFT', 'STEAL', 'TRACKER_TAMPERING', 'OUT_OF_LIMITS'];

      return [this.lockReasons.filter(l => emergencyLock.includes(l)), this.lockReasons.filter(l => !emergencyLock.includes(l))];
    },
    isUnlocked() {
      return this.status === 'UNLOCKED';
    },
    isLocked() {
      return this.status === 'LOCKED';
    },
    isPending() {
      return this.status === 'LOCK_PENDING';
    },
    getModalTitle() {
      switch (this.status) {
        case 'UNLOCKED':
          return this.$t('fleetProfile.buttons.lock') + ' Carro';
        case 'LOCK_PENDING':
          return this.$t('fleetProfile.modals.cancelLock.title');
        case 'LOCKED':
        default:
          return this.$t('fleetProfile.buttons.unlock') + ' Carro';
      }
    },
    getOkButton() {
      switch (this.status) {
        case 'UNLOCKED':
          return this.$t('fleetProfile.buttons.lock');
        case 'LOCK_PENDING':
          return this.$t('fleetProfile.modals.cancelLock.buttons.confirm');
        case 'LOCKED':
        default:
          return this.$t('fleetProfile.buttons.unlock');
      }
    },
  },
  methods: {
    ...mapActions({
      clearHeader: 'fleet/clearHeader',
      setHeader: 'fleet/header',
      setStatusOp: 'fleet/setStatusOperation',
    }),
    handleModalOpen() {
      this.windowClosed = false;
    },
    handleModalClose() {
      this.windowClosed = true;
    },
    handleLockSelect(e) {
      if (e.target.options.selectedIndex > -1) {
        this.lockReasonSelected = e.target.value;
      }
    },
    handleUnlockSelect(e) {
      if (e.target.options.selectedIndex > -1) {
        this.unlockReasonSelected = e.target.value;
      }
    },
    sendLockCar() {
      return this.$apollo
        .mutate({
          mutation: LOCK_CAR_V2,
          variables: {
            input: {
              vin: this.vin,
              type: this.lockReasonSelected,
            },
          },
        })
        .then(
          ({
            data: {
              createLock: { status },
            },
          }) => {
            const statusOperation = status === 'LOCK_SENT_TO_PROVIDER' ? 'LOCK_PENDING' : status;
            this.setStatusOp(statusOperation);
          }
        )
        .catch(err => {
          this.lockReasonError = true;
          this.showError(err);
        });
    },
    sendUnlockCar() {
      return this.$apollo
        .mutate({
          mutation: UNLOCK_CAR_V2,
          variables: {
            input: {
              vin: this.vin,
              type: this.unlockReasonSelected,
            },
          },
        })
        .then(
          ({
            data: {
              createUnlock: { status },
            },
          }) => {
            const statusOperation = status === 'UNLOCK_SENT_TO_PROVIDER' ? 'UNLOCK_PENDING' : status;
            this.setStatusOp(statusOperation);
          }
        )
        .catch(err => {
          this.unlockReasonError = true;
          this.showError(err);
        });
    },
    async handleOk() {
      this.isUnlocked ? await this.validateVin('lock') : await this.validateVin('unlock');
    },
    async showError(err) {
      const lockReason = this.getLastCommand?.lockReason;
      const errCode = err.graphQLErrors[0]?.message || 'UNKNOWN';
      const configSwal = {
        type: 'error',
        title: this.$t(`fleetProfile.errors.${errCode}`),
        text: `${this.$t(`fleetProfile.labels.lockReason`)}: ${
          this.$t(`fleetProfile.lockReasons.${lockReason}`) || this.$t(`fleetProfile.labels.unknownReason`)
        }`,
        showConfirmButton: true,
        confirmButtonText: this.$t('fleetProfile.buttons.close'),
      };
      return this.$swal(configSwal);
    },
    validateVin(command) {
      if (this.vin) {
        switch (command) {
          case 'unlock':
            this.sendUnlockCar();
            break;
          case 'lock':
            this.sendLockCar();
            break;
          default:
            alert(this.$t(`fleetProfile.labels.commandNotDefined`));
        }
      } else {
        alert(this.$t(`fleetProfile.labels.commandNotSent`));
      }
    },
  },
};
</script>
