<template>
  <div>
    <div v-if="profile">
      <b-row class="pb-1 justify-content-start">
        <b-col md="6" sm="12">
          <div class="d-flex flex-column">
            <profile-info
              :id="id"
              :profile="profile"
            />
          </div>
        </b-col>

        <b-col md="6" sm="12" class="text-md-right text-sm-left btn-content">
          <b-dropdown
            class="mr-2"
            text="Mensagem"
          >
            <b-dropdown-item data-test="driver-header__select-sms" @click="newMessage()">
              <i class="fa fa-comments" />
              SMS + Email
            </b-dropdown-item>
            <b-dropdown-item
              :href="'https://web.whatsapp.com/send?phone=' + profile.phone_number"
              target="_blank"
              data-test="driver-header__select-whatsapp"
            >
              <i class="fa fa-whatsapp" />
              WhatsApp
            </b-dropdown-item>
          </b-dropdown>

          <b-button
            v-if="!driverEditProfilePage"
            class="mr-2"
            @click.stop="goTo()"
          >
            <span>Editar</span>
          </b-button>
          <fleet-group-buttons v-if="hasBookCar" :vin="vin" :id="bookingCarId" />
          <fleet-lock-unlock-button :id="bookingCarId" :vin="vin" />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" sm="12" class="pt-3 pb-2">
          <profile-nav />
        </b-col>
      </b-row>
    </div>
    <contact-driver :modal_data="contact_modal_data" />
  </div>
</template>

<script>
import ProfileInfo from '@components/drivers/profile/profile-info'
import ProfileNav from '@components/drivers/profile/profile-nav'
import FleetGroupButtons from '@components/fleet/fleet-group-buttons';
import ContactDriver from '@components/modals/contact-driver';
import { formatStatus } from '@utils/driver';
import { emptyObject } from '@utils/validation';
import { mapActions, mapGetters } from 'vuex';
import FleetLockUnlockButton from "@components/fleet/fleet-lock-unlock-button.vue";
export default {
	name: 'DriverHeader',
	components: {
		ProfileNav,
		ProfileInfo,
		FleetGroupButtons,
    FleetLockUnlockButton,
    ContactDriver,
	},
  data() {
    return {
      contact_modal_data: {
        id: null,
        message: '',
      },
    }
  },
	props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('driver', {
      profile: 'header'
    }),
    hasBookCar () {
      if (!this.profile || emptyObject(this.profile) || emptyObject(this.profile.booking) || emptyObject(this.profile.booking.car)) return false
      return true
    },
    bookingCarId () {
      if (!this.hasBookCar) return ''
      return this.profile.booking.car.id
    },
    vin () {
      if (!this.hasBookCar) return ''
      return this.profile.booking.car.vin
    },
    driverEditProfilePage () {
      return (this.$route.name === 'DriverProfileEdit') ? true : false
    }
  },
	mounted () {
		this.loadProfile();
	},
	methods: {
    ...mapActions('driver', ['clearHeader', 'header']),
		loadProfile() {
      this.clearHeader();
      this.header({
        id: this.id
      });
		},
    newMessage() {
      this.contact_modal_data.id = this.id;
      this.$root.$emit('bv::close::modal');
      this.$root.$emit('bv::show::modal', 'modalContact');
    },
		setStatusBadge(status) {
      return formatStatus(status);
    },
    goTo() {
      this.$router.push({
        path: `/drivers/profile/${this.id}/edit`,
      });
    },
	}
}
</script>

<style scoped>
.card-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.btn-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
</style>
