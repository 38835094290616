var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hidde && _vm.id
    ? _c(
        "div",
        { staticClass: "btn-content" },
        [
          _c(
            "b-button",
            {
              class: _vm.noSignalClass,
              attrs: { disabled: _vm.isDisabled },
              on: {
                click: function ($event) {
                  return _vm.changeOperationCarV2()
                },
              },
            },
            [
              _c("i", { class: _vm.iconClass }),
              _vm._v(" " + _vm._s(_vm.statusText) + " "),
            ]
          ),
          _c("modal-operation-v3", {
            attrs: { id: _vm.id, vin: _vm.vin, status: _vm.status },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }