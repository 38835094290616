<template>
  <Layout>
    <region-guard
      :id="$route.params.id"
      entity="DRIVER"
    >
      <driver-header :id="$route.params.id" />
      <router-view />
    </region-guard>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import DriverHeader from '@components/drivers/header-new';
import RegionGuard from '@components/shared/region-guard/region-keeper';

export default {
  name: 'ProfileNew',
  components:  {
    DriverHeader,
    Layout,
    RegionGuard
  },
}
</script>
