var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "b-col",
        { staticClass: "mr-0 pr-0", attrs: { lg: "auto", md: "auto" } },
        [
          _vm.profile.status !== "SIGNUP"
            ? _c("driver-karma", { attrs: { profile: _vm.profile } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-inline" },
        [
          _c("k-avatar", {
            attrs: {
              src: _vm.profile.photo,
              title: _vm.$t("drivers.labels.showImage"),
              size: "md",
            },
          }),
        ],
        1
      ),
      _c("b-col", { staticClass: "ml-0 pl-0", attrs: { lg: "8", md: "10" } }, [
        _c(
          "h4",
          { staticClass: "card-title m-0" },
          [
            _c(
              "b-link",
              {
                staticClass: "card-name mr-1",
                attrs: {
                  to: `/drivers/profile/${_vm.id}`,
                  title: _vm.profile.name,
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.profile.name || _vm.$t("drivers.texts.anonymous")
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _vm.isActiveDriver
          ? _c(
              "div",
              { staticClass: "text-muted font-weight-normal mb-1" },
              [
                _vm._v(" " + _vm._s(_vm.$t("drivers.labels.status")) + ": "),
                _c(
                  "b-badge",
                  {
                    attrs: { variant: _vm.setStatusBadge(_vm.profile.status) },
                  },
                  [_vm._v(" " + _vm._s(_vm.statusBadge) + " ")]
                ),
                _vm._v(" - " + _vm._s(_vm.$t("drivers.labels.register")) + " "),
                _vm.profile.created_at
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("moment")(
                              _vm.profile.created_at,
                              "from",
                              true
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        " 0 " + _vm._s(_vm.$t("drivers.labels.days")) + " "
                      ),
                    ]),
                _vm._v(" " + _vm._s(_vm.$t("drivers.labels.behind")) + " "),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "text-muted font-weight-normal mb-1" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("drivers.labels.situationRegister")) +
                    ": "
                ),
                _vm.profile.status === "BANNED"
                  ? _c(
                      "b-badge",
                      {
                        staticClass: "text-uppercase",
                        attrs: { variant: "danger" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("up")(_vm.$t("drivers.labels.banned"))
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _c("b-badge", { attrs: { variant: "warning" } }, [
                      _vm._v(" " + _vm._s(_vm.profile.step) + " "),
                    ]),
                _vm._v(" - " + _vm._s(_vm.$t("drivers.labels.register")) + " "),
                _vm.profile.created_at
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(_vm.profile.created_at, "from", true)
                        )
                      ),
                    ])
                  : _c("span", [
                      _vm._v("0 " + _vm._s(_vm.$t("drivers.labels.days"))),
                    ]),
                _vm._v(" " + _vm._s(_vm.$t("drivers.labels.behind")) + " "),
              ],
              1
            ),
        _c(
          "div",
          [
            _vm.profile.booking && _vm.profile.booking.car
              ? _c(
                  "b-button",
                  {
                    staticClass: "pt-0 pl-0 text-uppercase",
                    attrs: {
                      link: "",
                      size: "sm",
                      variant: "link",
                      to: `/fleet/${_vm.profile.booking.car.id}`,
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-car" }),
                    _vm._v(" " + _vm._s(_vm.infoCar) + " "),
                  ]
                )
              : _vm._e(),
            _c("br"),
            !!_vm.planName
              ? _c(
                  "span",
                  { staticClass: "text-muted mb-1" },
                  [
                    _vm._v(" Plano: "),
                    _c("b-badge", { staticClass: "bg-kovi" }, [
                      _vm._v(_vm._s(_vm.planName)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "text-muted mt-1", attrs: { id: "clip-cpf" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("drivers.labels.document")) +
                    ": " +
                    _vm._s(_vm.profile.cpf) +
                    " "
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "link", size: "sm" },
                    on: { click: _vm.copyCpf },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-clone",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }