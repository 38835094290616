var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.id
    ? _c(
        "div",
        { staticClass: "fleet-group-buttons" },
        [
          _c(
            "div",
            [
              !_vm.showNewButton
                ? _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: { disabled: _vm.canResetToken },
                      on: {
                        click: function ($event) {
                          return _vm.confirmResetToken()
                        },
                      },
                    },
                    [_c("span", [_vm._v("Resetar Token")])]
                  )
                : _vm._e(),
              !_vm.showNewButton &&
              !_vm.isRental &&
              _vm.findGroups("ACL:SAFETY:VIRTUAL_TOKEN")
                ? _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.confirmVirtualToken()
                        },
                      },
                    },
                    [_c("span", [_vm._v("Liberar Token")])]
                  )
                : _vm._e(),
              !_vm.hidde
                ? _c(
                    "b-button-group",
                    { class: _vm.newButtonClass },
                    [
                      _c(
                        "b-button",
                        {
                          class: _vm.noSignalClass,
                          attrs: { disabled: _vm.isDisabled },
                          on: {
                            click: function ($event) {
                              return _vm.changeOperationCarV2()
                            },
                          },
                        },
                        [
                          _vm.status === "UNLOCK_PENDING"
                            ? [
                                _c("i", { staticClass: "fa fa-clock-o mr-2" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.noSignalWarning
                                        ? _vm.$t(
                                            "fleetProfile.buttons.noSignalUnlocking"
                                          )
                                        : _vm.$t(
                                            "fleetProfile.buttons.unlocking"
                                          )
                                    ) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                          ["LOCKED", "LOCK_PENDING", "UNLOCKED"].includes(
                            _vm.status
                          )
                            ? [
                                _vm.status === "UNLOCKED"
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa fa-unlock mr-2",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("fleetProfile.buttons.lock")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.status === "LOCKED"
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa fa-lock mr-2",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "fleetProfile.buttons.unlock"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.status === "LOCK_PENDING"
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa fa-clock-o mr-2",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.noSignalWarning
                                              ? _vm.$t(
                                                  "fleetProfile.buttons.noSignalLocking"
                                                )
                                              : _vm.$t(
                                                  "fleetProfile.buttons.locking"
                                                )
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.$t(
                                              "fleetProfile.buttons.cancel"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          !_vm.status
                            ? [
                                _c("i", { staticClass: "fa fa-clock-o mr-2" }),
                                _c("span", [_vm._v("Carregando...")]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("modal-operation-v2", {
            attrs: { id: _vm.id, vin: _vm.vin, "is-rental": _vm.isRental },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }