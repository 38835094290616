export const emails = [
  'anderson.leal@kovi.com.br',
  'jhonathan.lima@kovi.com.br',
  'ytallo.layon@kovi.com.br',
  'junior@kovi.com.br',
  'reginaldo.sousa@kovi.com.br',
  'carlos.sawa@kovi.com.br',
  'diego.haim@kovi.com.br',
  'tayna.araujo@kovi.com.br',
];
