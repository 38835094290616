var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-img", {
    staticClass: "mr-2 mt-1",
    class: _vm.size,
    attrs: {
      src: _vm.src,
      rounded: "",
      "blank-color": "#C0C0C0",
      title: _vm.title,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }